// =============================
// Imports
// =============================

// External Dependencies
import _pick from 'lodash/pick';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  token: null,
  isLogged: false,
  isAdmin: false,
  isModifying: false,
  isModifyingPassword: false,
  isLoadingSubscriptions: false,
  isLoadingOTP: false,
  enablingOTP: false,
  disablingOTP: false,
  otpData: null,
  data: {},
  subscriptions: {},
  notifications: [],
  relationsNotifications: {
    artists: [],
    catalogs: [],
    playlists: [],
    briefs: [],
    albums: [],
    tracks: [],
  },
  realtimeAuthKey: null,
  realtimeChannels: {},
};

// =============================
// Reducer
// =============================

export default function user(state = initialState, action) {
  switch (action.type) {
    case acts.SET_USER_SESSION_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case acts.SET_USER_INFO:
      return {
        ...state,
        data: action.payload,
        isLogged: true,
      };

    case acts.SET_USER_INFO_ONLY:
      return {
        ...state,
        data: action.payload,
      };

    case acts.SET_ADMIN_MODE:
      return {
        ...state,
        isAdmin: true,
      };

    case acts.GET_USER_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        isLoadingSubscriptions: true,
      };

    case acts.GET_USER_SUBSCRIPTIONS_SUCCESS:
    case acts.GET_USER_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoadingSubscriptions: false,
      };

    case acts.SET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case acts.MODIFY_USER_LOADING:
      return {
        ...state,
        isModifying: true,
      };

    case acts.MODIFY_USER_SUCCESS:
    case acts.MODIFY_USER_FAILURE:
      return {
        ...state,
        isModifying: false,
      };

    // reset action (no call front only)
    case acts.USER_OTP_RESET_STATE:
      return {
        ...state,
        isLoadingOTP: false,
        enablingOTP: false,
        otpData: null,
      };

    case acts.USER_OTP_INIT_LOADING:
      return {
        ...state,
        isLoadingOTP: true,
        enablingOTP: true,
      };

    case acts.USER_OTP_INIT_SUCCESS:
      return {
        ...state,
        otpData: {
          secret: action.payload.secret,
          otpauth_url: action.payload.otpauth_url,
          qrcode: action.payload.qrcode,
        },
        isLoadingOTP: false,
      };

    case acts.USER_OTP_INIT_FAILURE:
      return {
        ...state,
        enablingOTP: false,
        isLoadingOTP: false,
        otpData: null,
      };

    case acts.USER_OTP_ENABLE_LOADING:
    case acts.USER_OTP_SEND_BY_EMAIL_LOADING:
    case acts.USER_OTP_DISABLE_LOADING:
      return {
        ...state,
        isLoadingOTP: true,
      };

    case acts.USER_OTP_ENABLE_SUCCESS:
      return {
        ...state,
        data: action.payload.user,
        enablingOTP: false,
        isLoadingOTP: false,
        otpData: null,
      };

    case acts.USER_OTP_DISABLE_FAILURE:
    case acts.USER_OTP_SEND_BY_EMAIL_SUCCESS:
    case acts.USER_OTP_SEND_BY_EMAIL_FAILURE:
    case acts.USER_OTP_ENABLE_FAILURE:
      return {
        ...state,
        isLoadingOTP: false,
      };

    case acts.USER_OTP_DISABLE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          otp_enabled: false,
        },
        isLoadingOTP: false,
      };

    case acts.MODIFY_USER_PASSWORD_LOADING:
      return {
        ...state,
        isModifyingPassword: true,
      };

    case acts.MODIFY_USER_PASSWORD_SUCCESS:
    case acts.MODIFY_USER_PASSWORD_FAILURE:
      return {
        ...state,
        isModifyingPassword: false,
      };

    case acts.SET_LATEST_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    case acts.SET_RELATIONS_NOTIFICATIONS: {
      const emitted = !!state.relationsNotifications[action.payload.type]
        .find(notification => notification.notificationId === action.payload.notificationId);

      if (!emitted) {
        return {
          ...state,
          relationsNotifications: {
            ...state.relationsNotifications,
            [action.payload.type]: [
              ...state.relationsNotifications[action.payload.type],
              ...action.payload.docIds.map(docId => ({
                id: docId,
                ...action.payload.data,
                ..._pick(action.payload, ['notificationId', 'progress']),
              })),
            ],
          },
        };
      }

      return {
        ...state,
        relationsNotifications: {
          ...state.relationsNotifications,
          [action.payload.type]: [
            ...state.relationsNotifications[action.payload.type].map((notification) => {
              if (
                action.payload.notificationId === notification.notificationId
                && action.payload.docIds.includes(notification.id)
              ) {
                return {
                  ...notification,
                  ...action.payload.data,
                  ..._pick(action.payload, ['progress']),
                };
              }

              return notification;
            }),
          ],
        },
      };
    }

    case acts.CLEAR_RELATIONS_NOTIFICATIONS:
      return {
        ...state,
        relationsNotifications: {
          ...state.relationsNotifications,
          [action.payload.type]: [
            ...state.relationsNotifications[action.payload.type]
              // eslint-disable-next-line max-len
              .filter(notification => notification.notificationId !== action.payload.notificationId),
          ],
        },
      };

    case acts.SET_REALTIME_AUTH_KEY:
      return {
        ...state,
        realtimeAuthKey: action.payload,
      };

    case acts.SET_REALTIME_CHANNELS:
      return {
        ...state,
        realtimeChannels: action.payload,
      };

    case acts.LOGOUT_USER_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
