// =============================
// Imports
// =============================

// External Dependencies
import { Loader } from '@mewo/components';
import _get from 'lodash/get';
import _some from 'lodash/some';
import { connect } from 'react-redux';

// =============================
// Component
// =============================

function mapStateToProps({ auth, user, pages, meta, player, modo, sidepanel }) {
  return {
    active: _some(
      [
        // Auth
        _get(auth, 'isLoading', false),
        _get(auth, 'recover.isLoading', false),
        _get(auth, 'register.isLoading', false),
        _get(auth, 'resetpassword.isLoading', false),

        // User
        _get(user, 'isLoadingSubscriptions', false),
        _get(user, 'isModifying', false),
        _get(user, 'isModifyingPassword', false),
        _get(user, 'isLoadingOTP', false),

        // Dashboard
        _get(pages, 'organizationSettings.isFetching', false),
        _get(pages, 'organizationSettings.isModifying', false),

        _get(pages, 'agents.isFetching', false),
        _get(pages, 'agents.isCreating', false),
        _get(pages, 'agents.isModifying', false),
        _get(pages, 'agents.isDeleting', false),

        _get(pages, 'collaborators.isFetching', false),
        _get(pages, 'collaborators.isCreating', false),
        _get(pages, 'collaborators.isModifying', false),
        _get(pages, 'collaborators.isDeleting', false),

        _get(pages, 'providers.isFetching', false),
        _get(pages, 'providers.isValidating', false),
        _get(pages, 'providers.isDeleting', false),

        _get(pages, 'notifications.isFetching', false),
        _get(pages, 'notifications.isChecking', false),

        // Meta
        _get(meta, 'isInitializing', false),

        _get(meta, 'customFields.isFetching', false),

        _get(meta, 'bulkModal.isFetchingData', false),
        _get(meta, 'bulkModal.isUpdating', false),

        _get(meta, 'peoples.isFetching', false),
        _get(meta, 'peoples.isCreating', false),
        _get(meta, 'peoples.isModifying', []).length > 0,
        _get(meta, 'peoples.isDeleting', false),
        _get(meta, 'peoples.isExporting', false),

        _get(meta, 'publishers.isFetching', false),
        _get(meta, 'publishers.isCreating', false),
        _get(meta, 'publishers.isModifying', []).length > 0,
        _get(meta, 'publishers.uploads', []).length > 0,
        _get(meta, 'publishers.isDeleting', false),
        _get(meta, 'publishers.isExporting', false),
        _get(meta, 'publishers.isExportingTracks', false),

        _get(meta, 'labels.isFetching', false),
        _get(meta, 'labels.isCreating', false),
        _get(meta, 'labels.isModifying', []).length > 0,
        _get(meta, 'labels.uploads', []).length > 0,
        _get(meta, 'labels.isDeleting', false),
        _get(meta, 'labels.isExporting', false),
        _get(meta, 'labels.isExportingTracks', false),

        _get(meta, 'artists.isFetching', false),
        _get(meta, 'artists.isCreating', false),
        _get(meta, 'artists.isModifying', []).length > 0,
        _get(meta, 'artists.uploads', []).length > 0,
        _get(meta, 'artists.isDeleting', false),
        _get(meta, 'artists.isExporting', false),
        _get(meta, 'artists.isExportingTracks', false),

        _get(meta, 'catalogs.isFetching', false),
        _get(meta, 'catalogs.isCreating', false),
        _get(meta, 'catalogs.isModifying', []).length > 0,
        _get(meta, 'catalogs.uploads', []).length > 0,
        _get(meta, 'catalogs.isDeleting', false),
        _get(meta, 'catalogs.isExporting', false),
        _get(meta, 'catalogs.isExportingTracks', false),
        _get(meta, 'catalogs.isArchivingTracks', false),

        _get(meta, 'albums.isFetching', false),
        _get(meta, 'albums.isCreating', false),
        _get(meta, 'albums.isModifying', []).length > 0,
        _get(meta, 'albums.uploads', []).length > 0,
        _get(meta, 'albums.isDeleting', false),
        _get(meta, 'albums.isExporting', false),
        _get(meta, 'albums.isExportingTracks', false),
        _get(meta, 'albums.isArchivingTracks', false),
        _get(meta, 'albums.isDownloadingCopyright', false),

        _get(meta, 'playlists.isFetching', false),
        _get(meta, 'playlists.isCreating', false),
        _get(meta, 'playlists.isModifying', []).length > 0,
        _get(meta, 'playlists.uploads', []).length > 0,
        _get(meta, 'playlists.isDeleting', false),
        _get(meta, 'playlists.isExporting', false),
        _get(meta, 'playlists.isExportingTracks', false),
        _get(meta, 'playlists.isArchivingTracks', false),
        _get(meta, 'playlists.isDownloadingCopyright', false),

        _get(meta, 'refs.isHandlingTracks', []).length > 0,

        _get(meta, 'pitchs.isFetching', false),
        _get(meta, 'pitchs.isCreating', false),
        _get(meta, 'pitchs.isModifying', []).length > 0,
        _get(meta, 'pitchs.isDeleting', false),
        _get(meta, 'pitchs.isExporting', false),

        _get(meta, 'briefs.isFetching', false),
        _get(meta, 'briefs.isCreating', false),
        _get(meta, 'briefs.isModifying', []).length > 0,
        _get(meta, 'briefs.uploads', []).length > 0,
        _get(meta, 'briefs.isDeleting', false),
        _get(meta, 'briefs.isExporting', false),
        _get(meta, 'briefs.isExportingTracks', false),
        _get(meta, 'briefs.isArchivingTracks', false),
        _get(meta, 'briefs.isDownloadingCopyright', false),

        _get(meta, 'tracks.isFetching', false),
        _get(meta, 'tracks.isCreating', false),
        _get(meta, 'tracks.isModifying', []).length > 0,
        _get(meta, 'tracks.uploads', []).length > 0,
        _get(meta, 'tracks.isDeleting', false),
        _get(meta, 'tracks.isExportingTracks', false),
        _get(meta, 'tracks.isArchivingTracks', false),
        _get(meta, 'tracks.isDownloadingAudiofile', false),
        _get(meta, 'tracks.isDownloadingCopyright', false),
        _get(meta, 'tracks.isLauchingSimilaritySearch', false),

        _get(meta, 'ingestions.isFetching', false),
        _get(meta, 'ingestions.isCreating', false),
        _get(meta, 'ingestions.isUpdatingPostJob', false),
        _get(meta, 'ingestions.isDeleting', false),

        // Player
        _get(player, 'isFetchingAlbumTracks', false),
        _get(player, 'isFetchingPlaylistTracks', false),
        _get(player, 'isFetchingBriefTracks', false),

        // Modo
        _get(modo, 'configs.isFetching', false),
        _get(modo, 'configs.isCreating', false),

        _get(modo, 'config.isFetching', false),
        _get(modo, 'config.isModifyingPlan', false),
        _get(modo, 'config.isModifying', false),

        _get(modo, 'config.customisationsIsModifying', false),
        _get(modo, 'config.otherCustomisationsIsModifying', false),
        _get(modo, 'config.integrationsIsModifying', false),
        _get(modo, 'config.urlIsModifying', false),
        _get(modo, 'config.activeIsModifying', false),
        _get(modo, 'config.usersIsModifying', false),

        _get(modo, 'config.images.logo.status', 0) === 1,
        _get(modo, 'config.images.logo_variant.status', 0) === 1,
        _get(modo, 'config.images.logo_modal.status', 0) === 1,
        _get(modo, 'config.images.logo_email.status', 0) === 1,
        _get(modo, 'config.images.logo_filter_bar.status', 0) === 1,
        _get(modo, 'config.images.favicon.status', 0) === 1,

        _get(modo, 'configMenus.isFetching', false),
        _get(modo, 'configMenus.isModifying', false),

        _get(modo, 'configMenusOptions.isFetching', false),

        _get(modo, 'configLegal.isFetching', false),
        _get(modo, 'configLegal.isModifying', false),

        _get(modo, 'configPages.isFetching', false),
        _get(modo, 'configPage.isFetching', false),
        _get(modo, 'configPage.isCreating', false),
        _get(modo, 'configPage.isDuplicating', false),
        _get(modo, 'configPage.isModifying', false),
        _get(modo, 'configPage.isDeleting', false),

        _get(modo, 'configPage.images.seo_preview.status', 0) === 1,

        _get(modo, 'configPageModules.isFetching', false),
        _get(modo, 'configPageModules.isCreating', false),
        _get(modo, 'configPageModules.isModifying', []).length > 0,
        _get(modo, 'configPageModules.isUploading', []).length > 0,
        _get(modo, 'configPageModules.isDeletingUpload', []).length > 0,
        _get(modo, 'configPageModules.items.isUploading', []).length > 0,
        _get(modo, 'configPageModules.items.isDeletingUpload', []).length > 0,
        _get(modo, 'configPageModules.isReordering', false),
        _get(modo, 'configPageModules.isDeleting', false),

        _get(modo, 'configSuggestedPlaylists.isFetching', false),
        _get(modo, 'configSuggestedAlbums.isFetching', false),
        _get(modo, 'configSuggestedPlaylists.isModifying', false),
        _get(modo, 'configSuggestedAlbums.isModifying', false),

        _get(modo, 'users.isFetching', false),
        _get(modo, 'users.isModifying', false),
        _get(modo, 'users.isDeleting', false),
        _get(modo, 'users.isInviting', false),
        _get(modo, 'users.isExporting', false),

        _get(modo, 'usersImport.isCreating', false),
        _get(modo, 'usersImport.isModifying', false),
        _get(modo, 'usersImport.isUploading', false),
        _get(modo, 'usersImport.isLaunching', false),

        _get(modo, 'playlists.isFetching', false),
        _get(modo, 'playlists.isCreating', false),
        _get(modo, 'playlists.isDeleting', false),
        _get(modo, 'playlists.isAddingOwner', false),
        _get(modo, 'playlists.isDeletingOwner', false),

        _get(modo, 'stats.globalUser.isFetching', false),
        _get(modo, 'stats.globalPlays.isFetching', false),
        _get(modo, 'stats.globalDownloads.isFetching', false),
        _get(modo, 'stats.globalSearch.isFetching', false),
        _get(modo, 'stats.globalSearchStrings.isFetching', false),
        _get(modo, 'stats.globalSearchMaia.isFetching', false),
        _get(modo, 'stats.globalSearchTags.isFetching', false),

        // Sidepanel
        _get(sidepanel, 'panels.0.isLoading', false),
        _get(sidepanel, 'panels.0.isModifying', false),
        _get(sidepanel, 'panels.0.isAutotagging', false),
        _get(sidepanel, 'panels.0.isDuplicating', false),
        _get(sidepanel, 'panels.0.isUploading', false),
        _get(sidepanel, 'panels.0.isUploadingStems', false),
        _get(sidepanel, 'panels.0.isDeletingUpload', false),
        _get(sidepanel, 'panels.0.isDeletingStems', false),
        _get(sidepanel, 'panels.0.isDeleting', false),

        _some(
          Object.keys(_get(sidepanel, 'panels.0', {}))
            .filter(k => k.indexOf('isLoading-') !== -1)
            .map(k => _get(sidepanel, `panels.0.${k}`, false)),
          e => e,
        ),
        _some(
          Object.keys(_get(sidepanel, 'panels.0', {}))
            .filter(k => k.indexOf('isModifying-') !== -1)
            .map(k => _get(sidepanel, `panels.0.${k}`, false)),
          e => e,
        ),
      ],
      e => e,
    ),
  };
}

export default connect(mapStateToProps)(Loader);
