// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { getSesstionTokenWithOTP, getUserSessionToken } from '../../../../store/actions/AuthActions';

// Components
import LoginPage from '../../../layouts/auth/login';

// =============================
// Component
// =============================

function mapStateToProps(state) {
  return {
    otp: state.auth.otp,
  };
}

export default connect(mapStateToProps, {
  getUserSessionToken,
  getSesstionTokenWithOTP,
})(LoginPage);
