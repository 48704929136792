export const OTHER_ERRORS_INFO = 'OTHER_ERRORS_INFO';

/* Api Select Input */
export const API_SELECT_INPUT_FETCH_ERROR = 'API_SELECT_INPUT_FETCH_ERROR';
export const API_SELECT_INPUT_CREATION_ERROR = 'API_SELECT_INPUT_CREATION_ERROR';

/* Copy Link */
export const COPY_LINK_SUCCESS = 'COPY_LINK_SUCCESS';

/* User: modification */
export const MODIFY_USER_SUCCESS_INFO = 'MODIFY_USER_SUCCESS_INFO';
export const MODIFY_USER_FAILURE_INFO = 'MODIFY_USER_FAILURE_INFO';
export const MODIFY_USER_PASSWORD_SUCCESS_INFO = 'MODIFY_USER_PASSWORD_SUCCESS_INFO';
export const MODIFY_USER_PASSWORD_FAILURE_INFO = 'MODIFY_USER_PASSWORD_FAILURE_INFO';

export const USER_OTP_INIT_SUCCESS_INFO = 'USER_OTP_INIT_SUCCESS_INFO';
export const USER_OTP_INIT_FAILURE_INFO = 'USER_OTP_INIT_FAILURE_INFO';

export const USER_OTP_ENABLE_SUCCESS_INFO = 'USER_OTP_ENABLE_SUCCESS_INFO';
export const USER_OTP_ENABLE_FAILURE_INFO = 'USER_OTP_ENABLE_FAILURE_INFO';

export const USER_OTP_SEND_BY_EMAIL_SUCCESS_INFO = 'USER_OTP_SEND_BY_EMAIL_SUCCESS_INFO';
export const USER_OTP_SEND_BY_EMAIL_FAILURE_INFO = 'USER_OTP_SEND_BY_EMAIL_FAILURE_INFO';

export const USER_OTP_DISABLE_SUCCESS_INFO = 'USER_OTP_DISABLE_SUCCESS_INFO';
export const USER_OTP_DISABLE_FAILURE_INFO = 'USER_OTP_DISABLE_FAILURE_INFO';

/* User: collaborators */
export const CREATE_USER_COLLABORATOR_SUCCESS_INFO = 'CREATE_USER_COLLABORATOR_SUCCESS_INFO';
export const CREATE_USER_COLLABORATOR_FAILURE_INFO = 'CREATE_USER_COLLABORATOR_FAILURE_INFO';
export const MODIFY_USER_COLLABORATOR_SUCCESS_INFO = 'MODIFY_USER_COLLABORATOR_SUCCESS_INFO';
export const MODIFY_USER_COLLABORATOR_FAILURE_INFO = 'MODIFY_USER_COLLABORATOR_FAILURE_INFO';
export const DELETE_USER_COLLABORATOR_SUCCESS_INFO = 'DELETE_USER_COLLABORATOR_SUCCESS_INFO';
export const DELETE_USER_COLLABORATOR_FAILURE_INFO = 'DELETE_USER_COLLABORATOR_FAILURE_INFO';

/* User: agents */
export const CREATE_USER_AGENT_SUCCESS_INFO = 'CREATE_USER_AGENT_SUCCESS_INFO';
export const CREATE_USER_AGENT_FAILURE_INFO = 'CREATE_USER_AGENT_FAILURE_INFO';
export const MODIFY_USER_AGENT_SUCCESS_INFO = 'MODIFY_USER_AGENT_SUCCESS_INFO';
export const MODIFY_USER_AGENT_FAILURE_INFO = 'MODIFY_USER_AGENT_FAILURE_INFO';
export const DELETE_USER_AGENT_SUCCESS_INFO = 'DELETE_USER_AGENT_SUCCESS_INFO';
export const DELETE_USER_AGENT_FAILURE_INFO = 'DELETE_USER_AGENT_FAILURE_INFO';

/* User: providers */
export const VALIDATE_USER_PROVIDER_SUCCESS_INFO = 'VALIDATE_USER_PROVIDER_SUCCESS_INFO';
export const VALIDATE_USER_PROVIDER_FAILURE_INFO = 'VALIDATE_USER_PROVIDER_FAILURE_INFO';
export const DELETE_USER_PROVIDER_SUCCESS_INFO = 'DELETE_USER_PROVIDER_SUCCESS_INFO';
export const DELETE_USER_PROVIDER_FAILURE_INFO = 'DELETE_USER_PROVIDER_FAILURE_INFO';

/* User: requests */
export const USER_REQUEST_SUCCESS_INFO = 'USER_REQUEST_SUCCESS_INFO';

/* Meta: global */
export const EXPORT_META_DOCUMENTS_SUCCESS_INFO = 'EXPORT_META_DOCUMENTS_SUCCESS_INFO';
export const EXPORT_META_DOCUMENTS_FAILURE_INFO = 'EXPORT_META_DOCUMENTS_FAILURE_INFO';

/* Meta: bulk */
export const GET_META_BULK_MODAL_DATA_FAILURE_INFO = 'GET_META_BULK_MODAL_DATA_FAILURE_INFO';
export const UPDATE_META_BULK_FAILURE_INFO = 'UPDATE_META_BULK_FAILURE_INFO';

/* Meta: publishers */
export const MODIFY_META_PEOPLE_FAILURE_INFO = 'MODIFY_META_PEOPLE_FAILURE_INFO';
export const DELETE_META_PEOPLES_SUCCESS_INFO = 'DELETE_META_PEOPLES_SUCCESS_INFO';

/* Meta: publishers */
export const MODIFY_META_PUBLISHER_FAILURE_INFO = 'MODIFY_META_PUBLISHER_FAILURE_INFO';
export const UPLOAD_META_PUBLISHER_FILE_FAILURE_INFO = 'UPLOAD_META_PUBLISHER_FILE_FAILURE_INFO';
export const DELETE_META_PUBLISHERS_SUCCESS_INFO = 'DELETE_META_PUBLISHERS_SUCCESS_INFO';
export const EXPORT_META_PUBLISHER_TRACKS_SUCCESS_INFO = 'EXPORT_META_PUBLISHER_TRACKS_SUCCESS_INFO';
export const EXPORT_META_PUBLISHER_TRACKS_FAILURE_INFO = 'EXPORT_META_PUBLISHER_TRACKS_FAILURE_INFO';

/* Meta: labels */
export const MODIFY_META_LABEL_FAILURE_INFO = 'MODIFY_META_LABEL_FAILURE_INFO';
export const UPLOAD_META_LABEL_FILE_FAILURE_INFO = 'UPLOAD_META_LABEL_FILE_FAILURE_INFO';
export const DELETE_META_LABELS_SUCCESS_INFO = 'DELETE_META_LABELS_SUCCESS_INFO';
export const EXPORT_META_LABEL_TRACKS_SUCCESS_INFO = 'EXPORT_META_LABEL_TRACKS_SUCCESS_INFO';
export const EXPORT_META_LABEL_TRACKS_FAILURE_INFO = 'EXPORT_META_LABEL_TRACKS_FAILURE_INFO';

/* Meta: artists */
export const MODIFY_META_ARTIST_FAILURE_INFO = 'MODIFY_META_ARTIST_FAILURE_INFO';
export const UPLOAD_META_ARTIST_FILE_FAILURE_INFO = 'UPLOAD_META_ARTIST_FILE_FAILURE_INFO';
export const DELETE_META_ARTISTS_SUCCESS_INFO = 'DELETE_META_ARTISTS_SUCCESS_INFO';
export const EXPORT_META_ARTIST_TRACKS_SUCCESS_INFO = 'EXPORT_META_ARTIST_TRACKS_SUCCESS_INFO';
export const EXPORT_META_ARTIST_TRACKS_FAILURE_INFO = 'EXPORT_META_ARTIST_TRACKS_FAILURE_INFO';

/* Meta: catalogs */
export const MODIFY_META_CATALOG_FAILURE_INFO = 'MODIFY_META_CATALOG_FAILURE_INFO';
export const UPLOAD_META_CATALOG_FILE_FAILURE_INFO = 'UPLOAD_META_CATALOG_FILE_FAILURE_INFO';
export const DELETE_META_CATALOGS_SUCCESS_INFO = 'DELETE_META_CATALOGS_SUCCESS_INFO';
export const EXPORT_META_CATALOG_TRACKS_SUCCESS_INFO = 'EXPORT_META_CATALOG_TRACKS_SUCCESS_INFO';
export const EXPORT_META_CATALOG_TRACKS_FAILURE_INFO = 'EXPORT_META_CATALOG_TRACKS_FAILURE_INFO';
export const ARCHIVE_META_CATALOG_TRACKS_SUCCESS_INFO = 'ARCHIVE_META_CATALOG_TRACKS_SUCCESS_INFO';
export const ARCHIVE_META_CATALOG_TRACKS_FAILURE_INFO = 'ARCHIVE_META_CATALOG_TRACKS_FAILURE_INFO';

/* Meta: albums */
export const MODIFY_META_ALBUM_FAILURE_INFO = 'MODIFY_META_ALBUM_FAILURE_INFO';
export const UPLOAD_META_ALBUM_FILE_FAILURE_INFO = 'UPLOAD_META_ALBUM_FILE_FAILURE_INFO';
export const DELETE_META_ALBUMS_SUCCESS_INFO = 'DELETE_META_ALBUMS_SUCCESS_INFO';
export const EXPORT_META_ALBUM_TRACKS_SUCCESS_INFO = 'EXPORT_META_ALBUM_TRACKS_SUCCESS_INFO';
export const EXPORT_META_ALBUM_TRACKS_FAILURE_INFO = 'EXPORT_META_ALBUM_TRACKS_FAILURE_INFO';
export const ARCHIVE_META_ALBUM_TRACKS_SUCCESS_INFO = 'ARCHIVE_META_ALBUM_TRACKS_SUCCESS_INFO';
export const ARCHIVE_META_ALBUM_TRACKS_FAILURE_INFO = 'ARCHIVE_META_ALBUM_TRACKS_FAILURE_INFO';
export const DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS_INFO = 'DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS_INFO';
export const DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE_INFO = 'DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE_INFO';

/* Meta: playlists */
export const MODIFY_META_PLAYLIST_FAILURE_INFO = 'MODIFY_META_PLAYLIST_FAILURE_INFO';
export const UPLOAD_META_PLAYLIST_FILE_FAILURE_INFO = 'UPLOAD_META_PLAYLIST_FILE_FAILURE_INFO';
export const DELETE_META_PLAYLISTS_SUCCESS_INFO = 'DELETE_META_PLAYLISTS_SUCCESS_INFO';
export const EXPORT_META_PLAYLIST_TRACKS_SUCCESS_INFO = 'EXPORT_META_PLAYLIST_TRACKS_SUCCESS_INFO';
export const EXPORT_META_PLAYLIST_TRACKS_FAILURE_INFO = 'EXPORT_META_PLAYLIST_TRACKS_FAILURE_INFO';
export const ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS_INFO = 'ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS_INFO';
export const ARCHIVE_META_PLAYLIST_TRACKS_FAILURE_INFO = 'ARCHIVE_META_PLAYLIST_TRACKS_FAILURE_INFO';
export const DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS_INFO = 'DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS_INFO';
export const DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE_INFO = 'DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE_INFO';

/* Meta: refs */
export const ADD_META_TRACKS_TO_REF_FAILURE_INFO = 'ADD_META_TRACKS_TO_REF_FAILURE_INFO';
export const REMOVE_META_TRACKS_FROM_REF_FAILURE_INFO = 'REMOVE_META_TRACKS_FROM_REF_FAILURE_INFO';

/* Meta: pitchs */
export const MODIFY_META_PITCH_FAILURE_INFO = 'MODIFY_META_PITCH_FAILURE_INFO';
export const DELETE_META_PITCHS_SUCCESS_INFO = 'DELETE_META_PITCHS_SUCCESS_INFO';

/* Meta: briefs */
export const MODIFY_META_BRIEF_FAILURE_INFO = 'MODIFY_META_BRIEF_FAILURE_INFO';
export const UPLOAD_META_BRIEF_FILE_FAILURE_INFO = 'UPLOAD_META_BRIEF_FILE_FAILURE_INFO';
export const DELETE_META_BRIEFS_SUCCESS_INFO = 'DELETE_META_BRIEFS_SUCCESS_INFO';
export const EXPORT_META_BRIEF_TRACKS_SUCCESS_INFO = 'EXPORT_META_BRIEF_TRACKS_SUCCESS_INFO';
export const EXPORT_META_BRIEF_TRACKS_FAILURE_INFO = 'EXPORT_META_BRIEF_TRACKS_FAILURE_INFO';
export const ARCHIVE_META_BRIEF_TRACKS_SUCCESS_INFO = 'ARCHIVE_META_BRIEF_TRACKS_SUCCESS_INFO';
export const ARCHIVE_META_BRIEF_TRACKS_FAILURE_INFO = 'ARCHIVE_META_BRIEF_TRACKS_FAILURE_INFO';
export const DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS_INFO = 'DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS_INFO';
export const DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE_INFO = 'DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE_INFO';

/* Meta: tracks */
export const MODIFY_META_TRACK_FAILURE_INFO = 'MODIFY_META_TRACK_FAILURE_INFO';
export const UPLOAD_META_TRACK_FILE_FAILURE_INFO = 'UPLOAD_META_TRACK_FILE_FAILURE_INFO';
export const DELETE_META_TRACKS_SUCCESS_INFO = 'DELETE_META_TRACKS_SUCCESS_INFO';
export const EXPORT_META_TRACK_SUCCESS_INFO = 'EXPORT_META_TRACK_SUCCESS_INFO';
export const EXPORT_META_TRACK_FAILURE_INFO = 'EXPORT_META_TRACK_FAILURE_INFO';
export const ARCHIVE_META_TRACK_SUCCESS_INFO = 'ARCHIVE_META_TRACK_SUCCESS_INFO';
export const ARCHIVE_META_TRACK_FAILURE_INFO = 'ARCHIVE_META_TRACK_FAILURE_INFO';
export const DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE_INFO = 'DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE_INFO';
export const DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS_INFO = 'DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS_INFO';
export const DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE_INFO = 'DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE_INFO';
export const LAUNCH_SIMILARITY_SEARCH_SUCCESS_INFO = 'LAUNCH_SIMILARITY_SEARCH_SUCCESS_INFO';
export const SIMILARITY_SEARCH_SUCCESS_INFO = 'SIMILARITY_SEARCH_SUCCESS_INFO';
export const SIMILARITY_SEARCH_FAILURE_INFO = 'SIMILARITY_SEARCH_FAILURE_INFO';

/* Meta: ingestions */
export const DELETE_META_INGESTIONS_SUCCESS_INFO = 'DELETE_META_INGESTIONS_SUCCESS_INFO';

/* Player */
export const GET_AND_SET_ALBUM_TRACKS_FAILURE_INFO = 'GET_AND_SET_ALBUM_TRACKS_FAILURE_INFO';
export const GET_AND_SET_PLAYLIST_TRACKS_FAILURE_INFO = 'GET_AND_SET_PLAYLIST_TRACKS_FAILURE_INFO';

/* Modo: general */
export const UPLOAD_MODO_CONFIG_IMAGE_FAILURE_INFO = 'UPLOAD_MODO_CONFIG_IMAGE_FAILURE_INFO';
export const DELETE_MODO_CONFIG_IMAGE_FAILURE_INFO = 'DELETE_MODO_CONFIG_IMAGE_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE_INFO';

export const MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE_INFO = 'MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE_INFO';

/* Modo: pages */
export const GET_MODO_CONFIG_PAGE_FAILURE_INFO = 'GET_MODO_CONFIG_PAGE_FAILURE_INFO';
export const CREATE_MODO_CONFIG_PAGE_FAILURE_INFO = 'CREATE_MODO_CONFIG_PAGE_FAILURE_INFO';
export const DUPLICATE_MODO_CONFIG_PAGE_FAILURE_INFO = 'DUPLICATE_MODO_CONFIG_PAGE_FAILURE_INFO';
export const MODIFY_MODO_CONFIG_PAGE_FAILURE_INFO = 'MODIFY_MODO_CONFIG_PAGE_FAILURE_INFO';
export const DELETE_MODO_CONFIG_PAGE_FAILURE_INFO = 'DELETE_MODO_CONFIG_PAGE_FAILURE_INFO';

export const UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE_INFO = 'UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE_INFO';
export const DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE_INFO = 'DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE_INFO';

/* Modo: pages modules */
export const GET_MODO_CONFIG_PAGE_MODULES_FAILURE_INFO = 'GET_MODO_CONFIG_PAGE_MODULES_FAILURE_INFO';
export const CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO = 'CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO';
export const MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO = 'MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO';
export const MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE_INFO = 'MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE_INFO';
export const DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS_INFO = 'DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS_INFO';
export const DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO = 'DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO';
export const UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE_INFO = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE_INFO';
export const UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE_INFO = 'UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE_INFO';
export const DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE_INFO = 'DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE_INFO';
export const DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE_INFO = 'DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE_INFO';

/* Modo: suggestions */
export const MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR_INFO = 'MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR_INFO';
export const MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR_INFO = 'MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR_INFO';

/* Modo: users */
export const MODIFY_MODO_USER_FAILURE_INFO = 'MODIFY_MODO_USER_FAILURE_INFO';
export const DELETE_MODO_USER_FAILURE_INFO = 'DELETE_MODO_USER_FAILURE_INFO';

/* Modo: users invite */
export const INVITE_MODO_USER_SUCCESS_INFO = 'INVITE_MODO_USER_SUCCESS_INFO';
export const INVITE_MODO_USER_FAILURE_INFO = 'INVITE_MODO_USER_FAILURE_INFO';

export const EXPORT_MODO_USERS_SUCCESS_INFO = 'EXPORT_MODO_USERS_SUCCESS_INFO';
export const EXPORT_MODO_USERS_FAILURE_INFO = 'EXPORT_MODO_USERS_FAILURE_INFO';

/* Modo: users import */
export const MODIFY_MODO_USER_IMPORT_FAILURE_INFO = 'MODIFY_MODO_USER_IMPORT_FAILURE_INFO';
export const UPLOAD_MODO_USER_IMPORT_FAILURE_INFO = 'UPLOAD_MODO_USER_IMPORT_FAILURE_INFO';
export const LAUNCH_MODO_USER_IMPORT_SUCCESS_INFO = 'LAUNCH_MODO_USER_IMPORT_SUCCESS_INFO';
export const LAUNCH_MODO_USER_IMPORT_FAILURE_INFO = 'LAUNCH_MODO_USER_IMPORT_FAILURE_INFO';

/* Side panels */
export const GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE_INFO = uuid => `GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE_INFO-${uuid}`;
export const MODIFY_SIDE_PANEL_DATA_FAILURE_INFO = uuid => `MODIFY_SIDE_PANEL_DATA_FAILURE_INFO-${uuid}`;
export const MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE_INFO = uuid => `MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE_INFO-${uuid}`;
export const AUTOTAG_SIDE_PANEL_SUCCESS_INFO = uuid => `AUTOTAG_SIDE_PANEL_SUCCESS_INFO-${uuid}`;
export const AUTOTAG_SIDE_PANEL_FAILURE_INFO = uuid => `AUTOTAG_SIDE_PANEL_FAILURE_INFO-${uuid}`;
export const DUPLICATE_SIDE_PANEL_DATA_FAILURE_INFO = uuid => `DUPLICATE_SIDE_PANEL_DATA_FAILURE_INFO-${uuid}`;
export const UPLOAD_SIDE_PANEL_FILE_FAILURE_INFO = uuid => `UPLOAD_SIDE_PANEL_FILE_FAILURE_INFO-${uuid}`;
export const DELETE_SIDE_PANEL_FILE_FAILURE_INFO = uuid => `DELETE_SIDE_PANEL_FILE_FAILURE_INFO-${uuid}`;
export const DELETE_SIDE_PANEL_FAILURE_INFO = uuid => `DELETE_SIDE_PANEL_FAILURE_INFO-${uuid}`;
