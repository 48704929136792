// =============================
// Imports
// =============================

import { composeValidators, minLength, mustBeEmail, required } from '../index';

// =============================
// Validator
// =============================

const validator = {
  email: composeValidators(required, mustBeEmail),
  otp: composeValidators(required, minLength(6)),
  password: composeValidators(required, minLength(6)),
};

export default validator;
