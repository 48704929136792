// =============================
// Imports
// =============================

// Actions
import { clearNotification, setNotification } from '../actions/NotificationActions';

// Constants
import * as acts from '../constants/ActionTypes';
import * as ntfs from '../constants/NotificationTypes';

// =============================
// Middleware
// =============================

const notificationsMiddleware = store => next => (action) => {
  switch (true) {
    /* Modify user */
    /* Clear old notifications when modifying user */
    case action.type === acts.MODIFY_USER_LOADING:
      store.dispatch(clearNotification(ntfs.MODIFY_USER_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.MODIFY_USER_FAILURE_INFO));
      return next(action);

    case action.type === acts.MODIFY_USER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 120000,
        }),
      );
      return next(action);

    /* Clear old notifications when modifying user password */
    case action.type === acts.MODIFY_USER_PASSWORD_LOADING:
      store.dispatch(clearNotification(ntfs.MODIFY_USER_PASSWORD_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.MODIFY_USER_PASSWORD_FAILURE_INFO));
      return next(action);

    case action.type === acts.MODIFY_USER_PASSWORD_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_PASSWORD_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    /* Clear old notifications when modifying OTP */
    case action.type === acts.USER_OTP_INIT_LOADING:
    case action.type === acts.USER_OTP_ENABLE_LOADING:
    case action.type === acts.USER_OTP_SEND_BY_EMAIL_LOADING:
    case action.type === acts.USER_OTP_DISABLE_LOADING:
      store.dispatch(clearNotification(ntfs.USER_OTP_INIT_FAILURE_INFO));
      store.dispatch(clearNotification(ntfs.USER_OTP_ENABLE_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.USER_OTP_ENABLE_FAILURE_INFO));
      store.dispatch(clearNotification(ntfs.USER_OTP_SEND_BY_EMAIL_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.USER_OTP_SEND_BY_EMAIL_FAILURE_INFO));
      store.dispatch(clearNotification(ntfs.USER_OTP_DISABLE_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.USER_OTP_DISABLE_FAILURE_INFO));
      return next(action);

    case action.type === acts.USER_OTP_INIT_SUCCESS:
      return next(action);

    case action.type === acts.USER_OTP_INIT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_INIT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 8000,
        }),
      );
      return next(action);

    case action.type === acts.USER_OTP_ENABLE_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_ENABLE_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 5000,
        }),
      );
      return next(action);

    case action.type === acts.USER_OTP_ENABLE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_ENABLE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 8000,
        }),
      );
      return next(action);

    case action.type === acts.USER_OTP_SEND_BY_EMAIL_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_SEND_BY_EMAIL_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 5000,
        }),
      );
      return next(action);

    case action.type === acts.USER_OTP_SEND_BY_EMAIL_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_SEND_BY_EMAIL_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.USER_OTP_DISABLE_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_DISABLE_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.USER_OTP_DISABLE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_OTP_DISABLE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_PASSWORD_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_PASSWORD_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Collaborators */
    case action.type === acts.CREATE_USER_COLLABORATOR_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CREATE_USER_COLLABORATOR_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.CREATE_USER_COLLABORATOR_FAILURE: {
      if (action.payload) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.CREATE_USER_COLLABORATOR_FAILURE_INFO,
            message: action.payload.message,
            reqId: action.payload.reqId,
            type: 'error',
            duration: 12000,
          }),
        );
      }

      return next(action);
    }

    case action.type === acts.MODIFY_USER_COLLABORATOR_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_COLLABORATOR_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_COLLABORATOR_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_COLLABORATOR_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_USER_COLLABORATOR_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_USER_COLLABORATOR_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_USER_COLLABORATOR_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_USER_COLLABORATOR_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Agents */
    case action.type === acts.CREATE_USER_AGENT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CREATE_USER_AGENT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.CREATE_USER_AGENT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CREATE_USER_AGENT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_AGENT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_AGENT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_AGENT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_AGENT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_USER_AGENT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_USER_AGENT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_USER_AGENT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_USER_AGENT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Providers */
    case action.type === acts.VALIDATE_USER_PROVIDER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.VALIDATE_USER_PROVIDER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.VALIDATE_USER_PROVIDER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.VALIDATE_USER_PROVIDER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_USER_PROVIDER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_USER_PROVIDER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_USER_PROVIDER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_USER_PROVIDER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* User requests */
    case action.type === acts.USER_REQUEST_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.USER_REQUEST_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    /* Meta */
    case action.type === acts.EXPORT_META_LABELS_SUCCESS:
    case action.type === acts.EXPORT_META_PUBLISHERS_SUCCESS:
    case action.type === acts.EXPORT_META_ARTISTS_SUCCESS:
    case action.type === acts.EXPORT_META_CATALOGS_SUCCESS:
    case action.type === acts.EXPORT_META_ALBUMS_SUCCESS:
    case action.type === acts.EXPORT_META_PLAYLISTS_SUCCESS:
    case action.type === acts.EXPORT_META_PITCHS_SUCCESS:
    case action.type === acts.EXPORT_META_BRIEFS_SUCCESS:
    case action.type === acts.EXPORT_META_PEOPLES_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_DOCUMENTS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_LABELS_FAILURE:
    case action.type === acts.EXPORT_META_PUBLISHERS_FAILURE:
    case action.type === acts.EXPORT_META_ARTISTS_FAILURE:
    case action.type === acts.EXPORT_META_CATALOGS_FAILURE:
    case action.type === acts.EXPORT_META_ALBUMS_FAILURE:
    case action.type === acts.EXPORT_META_PLAYLISTS_FAILURE:
    case action.type === acts.EXPORT_META_PITCHS_FAILURE:
    case action.type === acts.EXPORT_META_BRIEFS_FAILURE:
    case action.type === acts.EXPORT_META_PEOPLES_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_DOCUMENTS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.GET_META_BULK_MODAL_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_META_BULK_MODAL_DATA_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPDATE_META_BULK_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPDATE_META_BULK_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_PEOPLE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_PEOPLE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_PUBLISHER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_PUBLISHER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_LABEL_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_LABEL_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_ARTIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_ARTIST_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_CATALOG_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_CATALOG_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_ALBUM_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_ALBUM_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_PITCH_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_PITCH_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_BRIEF_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_BRIEF_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_META_TRACK_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_META_TRACK_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_PUBLISHER_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_PUBLISHER_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_LABEL_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_LABEL_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_ARTIST_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_ARTIST_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_CATALOG_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_CATALOG_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_ALBUM_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_ALBUM_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_PLAYLIST_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_PLAYLIST_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_BRIEF_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_BRIEF_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_META_TRACK_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_META_TRACK_FILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_PEOPLES_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_PEOPLES_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_PUBLISHERS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_PUBLISHERS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_LABELS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_LABELS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_ARTISTS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_ARTISTS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_CATALOGS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_CATALOGS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_ALBUMS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_ALBUMS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_PLAYLISTS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_PLAYLISTS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_PITCHS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_PITCHS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_BRIEFS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_BRIEFS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_TRACKS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_META_INGESTIONS_SUCCESS && action.payload.showNotification:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_META_INGESTIONS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_LABEL_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_LABEL_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_LABEL_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_LABEL_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_PUBLISHER_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_PUBLISHER_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_PUBLISHER_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_PUBLISHER_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_ARTIST_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_ARTIST_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_ARTIST_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_ARTIST_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_CATALOG_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_CATALOG_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_CATALOG_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_CATALOG_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_CATALOG_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_CATALOG_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_CATALOG_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_CATALOG_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_ALBUM_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_ALBUM_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_ALBUM_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_ALBUM_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_ALBUM_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_ALBUM_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_ALBUM_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_ALBUM_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_ALBUM_COPYRIGHT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_ALBUM_COPYRIGHT_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_PLAYLIST_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_PLAYLIST_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_PLAYLIST_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_PLAYLIST_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_PLAYLIST_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_PLAYLIST_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_PLAYLIST_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_PLAYLIST_COPYRIGHT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_PLAYLIST_COPYRIGHT_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ADD_META_TRACKS_TO_REF_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ADD_META_TRACKS_TO_REF_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.REMOVE_META_TRACKS_FROM_REF_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.REMOVE_META_TRACKS_FROM_REF_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_BRIEF_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_BRIEF_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_BRIEF_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_BRIEF_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_BRIEF_TRACKS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_BRIEF_TRACKS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_BRIEF_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_BRIEF_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_BRIEF_COPYRIGHT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_BRIEF_COPYRIGHT_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_TRACK_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_TRACK_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_META_TRACK_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_META_TRACK_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_TRACK_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_TRACK_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ARCHIVE_META_TRACK_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ARCHIVE_META_TRACK_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_TRACK_AUDIOFILE_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_TRACK_COPYRIGHT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_META_TRACK_COPYRIGHT_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.LAUNCH_SIMILARITY_SEARCH_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.LAUNCH_SIMILARITY_SEARCH_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    /* Player */
    case action.type === acts.GET_AND_SET_ALBUM_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_AND_SET_ALBUM_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.GET_AND_SET_PLAYLIST_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_AND_SET_PLAYLIST_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.GET_AND_SET_BRIEF_TRACKS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_AND_SET_BRIEF_TRACKS_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo general */
    case action.type === acts.UPLOAD_MODO_CONFIG_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_MODO_CONFIG_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_MAIN_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_URL_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_ACTIVE_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_INTEGRATIONS_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_CUSTOMISATIONS_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_OTHER_CUSTOMISATIONS_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_USERS_SETTINGS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo pages */
    case action.type === acts.GET_MODO_CONFIG_PAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_MODO_CONFIG_PAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.CREATE_MODO_CONFIG_PAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CREATE_MODO_CONFIG_PAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DUPLICATE_MODO_CONFIG_PAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_MODO_CONFIG_PAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_PAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_PAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_PAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_MODO_CONFIG_PAGE_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_PAGE_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo page modules */
    case action.type === acts.GET_MODO_CONFIG_PAGE_MODULES_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_MODO_CONFIG_PAGE_MODULES_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CREATE_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_PAGE_MODULE_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_PAGE_MODULE_ITEM_IMAGE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_PAGE_MODULES_ORDER_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_PAGE_MODULE_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_CONFIG_PAGE_MODULE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo suggestions */
    case action.type === acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_ERROR_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_ERROR_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo users invite */
    case action.type === acts.INVITE_MODO_USER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.INVITE_MODO_USER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.INVITE_MODO_USER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.INVITE_MODO_USER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo users export */
    case action.type === acts.EXPORT_MODO_USERS_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_MODO_USERS_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.EXPORT_MODO_USERS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.EXPORT_MODO_USERS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo users */
    case action.type === acts.MODIFY_MODO_USER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_USER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_MODO_USER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_MODO_USER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Modo users import */
    case action.type === acts.MODIFY_MODO_USER_IMPORT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_MODO_USER_IMPORT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_MODO_USER_IMPORT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_MODO_USER_IMPORT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.LAUNCH_MODO_USER_IMPORT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.LAUNCH_MODO_USER_IMPORT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.LAUNCH_MODO_USER_IMPORT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.LAUNCH_MODO_USER_IMPORT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Side panel */
    case action.type === acts.GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_SIDE_PANEL_ADDITIONAL_DATA_FAILURE_INFO(
            `${action.payload.uuid}-${action.payload.additionalDataKey}`,
          ),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_SIDE_PANEL_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_SIDE_PANEL_DATA_FAILURE_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_SIDE_PANEL_ADDITIONAL_DATA_FAILURE_INFO(
            `${action.payload.uuid}-${action.payload.additionalDataKey}`,
          ),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.AUTOTAG_SIDE_PANEL_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.AUTOTAG_SIDE_PANEL_SUCCESS_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.AUTOTAG_SIDE_PANEL_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.AUTOTAG_SIDE_PANEL_FAILURE_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DUPLICATE_SIDE_PANEL_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_SIDE_PANEL_DATA_FAILURE_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.UPLOAD_SIDE_PANEL_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.UPLOAD_SIDE_PANEL_FILE_FAILURE_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_SIDE_PANEL_FILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_SIDE_PANEL_FILE_FAILURE_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.DELETE_SIDE_PANEL_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DELETE_SIDE_PANEL_FAILURE_INFO(action.payload.uuid),
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    // ======================
    // Other errors (those that should not happen)
    // ======================
    case action.type === acts.GET_OPTIONS_FAILURE: /* Core */
    case action.type === acts.GET_ORGANIZATION_SETTINGS_FAILURE: /* Dashboard */
    case action.type === acts.MODIFY_ORGANIZATION_SETTINGS_FAILURE:
    case action.type === acts.GET_USER_SUBSCRIPTIONS_FAILURE:
    case action.type === acts.GET_USER_COLLABORATORS_FAILURE:
    case action.type === acts.GET_USER_AGENTS_FAILURE:
    case action.type === acts.GET_USER_PROVIDERS_FAILURE:
    case action.type === acts.USER_REQUEST_FAILURE:
    case action.type === acts.GET_USER_NOTIFICATIONS_FAILURE:
    case action.type === acts.GET_LATEST_NOTIFICATIONS_FAILURE:
    case action.type === acts.CHECK_USER_NOTIFICATIONS_FAILURE:
    case action.type === acts.INITIALIZE_META_FAILURE: /* Meta */
    case action.type === acts.LAUNCH_SIMILARITY_SEARCH_FAILURE:
    case action.type === acts.GET_META_PEOPLES_FAILURE:
    case action.type === acts.REFRESH_META_PEOPLES_FAILURE:
    case action.type === acts.CREATE_META_PEOPLE_FAILURE:
    case action.type === acts.DELETE_META_PEOPLES_FAILURE:
    case action.type === acts.GET_META_PUBLISHERS_FAILURE:
    case action.type === acts.REFRESH_META_PUBLISHERS_FAILURE:
    case action.type === acts.CREATE_META_PUBLISHER_FAILURE:
    case action.type === acts.DELETE_META_PUBLISHERS_FAILURE:
    case action.type === acts.GET_META_LABELS_FAILURE:
    case action.type === acts.REFRESH_META_LABELS_FAILURE:
    case action.type === acts.CREATE_META_LABEL_FAILURE:
    case action.type === acts.DELETE_META_LABELS_FAILURE:
    case action.type === acts.GET_META_ARTISTS_FAILURE:
    case action.type === acts.REFRESH_META_ARTISTS_FAILURE:
    case action.type === acts.CREATE_META_ARTIST_FAILURE:
    case action.type === acts.DELETE_META_ARTISTS_FAILURE:
    case action.type === acts.GET_META_CATALOGS_FAILURE:
    case action.type === acts.REFRESH_META_CATALOGS_FAILURE:
    case action.type === acts.CREATE_META_CATALOG_FAILURE:
    case action.type === acts.DELETE_META_CATALOGS_FAILURE:
    case action.type === acts.GET_META_ALBUMS_FAILURE:
    case action.type === acts.REFRESH_META_ALBUMS_FAILURE:
    case action.type === acts.CREATE_META_ALBUM_FAILURE:
    case action.type === acts.DELETE_META_ALBUMS_FAILURE:
    case action.type === acts.GET_META_PLAYLISTS_FAILURE:
    case action.type === acts.REFRESH_META_PLAYLISTS_FAILURE:
    case action.type === acts.CREATE_META_PLAYLIST_FAILURE:
    case action.type === acts.DELETE_META_PLAYLISTS_FAILURE:
    case action.type === acts.GET_META_PITCHS_FAILURE:
    case action.type === acts.REFRESH_META_PITCHS_FAILURE:
    case action.type === acts.CREATE_META_PITCH_FAILURE:
    case action.type === acts.DELETE_META_PITCHS_FAILURE:
    case action.type === acts.GET_META_BRIEFS_FAILURE:
    case action.type === acts.REFRESH_META_BRIEFS_FAILURE:
    case action.type === acts.CREATE_META_BRIEF_FAILURE:
    case action.type === acts.DELETE_META_BRIEFS_FAILURE:
    case action.type === acts.GET_META_TRACKS_FAILURE:
    case action.type === acts.REFRESH_META_TRACKS_FAILURE:
    case action.type === acts.CREATE_META_TRACK_FAILURE:
    case action.type === acts.DELETE_META_TRACKS_FAILURE:
    case action.type === acts.GET_META_INGESTIONS_FAILURE:
    case action.type === acts.REFRESH_META_INGESTIONS_FAILURE:
    case action.type === acts.CREATE_META_INGESTION_FAILURE:
    case action.type === acts.DELETE_META_INGESTIONS_FAILURE:
    case action.type === acts.GET_MODO_CONFIGS_FAILURE: /* Modo */
    case action.type === acts.CREATE_MODO_CONFIG_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_FAILURE:
    case action.type === acts.MODIFY_MODO_CONFIG_PLAN_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_MENUS_FAILURE:
    case action.type === acts.MODIFY_MODO_CONFIG_MENUS_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_MENUS_OPTIONS_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_PAGES_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_LEGAL_FAILURE:
    case action.type === acts.MODIFY_MODO_CONFIG_LEGAL_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE:
    case action.type === acts.MODIFY_MODO_CONFIG_SUGGESTED_PLAYLISTS_FAILURE:
    case action.type === acts.GET_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE:
    case action.type === acts.MODIFY_MODO_CONFIG_SUGGESTED_ALBUMS_FAILURE:
    case action.type === acts.GET_MODO_USERS_FAILURE:
    case action.type === acts.CREATE_MODO_USER_IMPORT_FAILURE:
    case action.type === acts.GET_REALTIME_CHANNELS_FAILURE:
      if (action.payload) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.OTHER_ERRORS_INFO,
            message: action.payload.message,
            reqId: action.payload.reqId,
            type: 'error',
            duration: 12000,
          }),
        );
      }

      return next(action);

    default:
      return next(action);
  }
};

export default notificationsMiddleware;
