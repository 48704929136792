import Tracker from '@openreplay/tracker/cjs';

export default class OpenRelay {
  /** @type {Tracker | null} */
  static tracker = null;

  static get() {
    if (!process.env.OPENREPLAY_PROJECT_KEY) {
      return null;
    }

    if (!this.tracker) {
      this.tracker = new Tracker({
        projectKey: process.env.OPENREPLAY_PROJECT_KEY,
        __DISABLE_SECURE_MODE: process.env.APP_ENV === 'local',
        defaultInputMode: 0,
        network: {
          captureInIframes: true,
          capturePayload: true,
          failuresOnly: false,
        },
      });
    }

    return this.tracker;
  }
}
