// =============================
// Imports
// =============================

import { Typo } from '@mewo/components';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import Link from '../../../other/link';
import NoAuthLanguageSwitcher from '../../../other/noAuthLanguageSwitcher';
import Input from '../../../presentationals/inputs/input';
import MewoInput from '../../../presentationals/mewoInput';

import validator from '../../../../validators/auth/login';

import { InputWrapper } from '../../global.styles';
import { Background, Content, ContentWrapper, FormSubmit, Logo, Notification, Wrapper } from '../auth.styles';
import { ForgotPasswordLink, RegisterLink } from './styles';

// =============================
// Page
// =============================

const DisplayForm = ({ getUserSessionToken, t }) => (
  <>
    <Form onSubmit={getUserSessionToken}>
      {({ submitError, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {submitError && <Notification type="error">{submitError}</Notification>}
          <InputWrapper>
            <Field
              name="email"
              component={MewoInput}
              use={Input}
              type="text"
              label={t('common:form.email')}
              validate={validator.email}
            />
            <Field
              name="password"
              component={MewoInput}
              use={Input}
              type="password"
              label={t('common:form.password')}
              validate={validator.password}
            />
          </InputWrapper>

          <FormSubmit type="submit" disabled={submitting} fullWidth>
            {t('pages:login.login')}
          </FormSubmit>
        </form>
      )}
    </Form>

    <ForgotPasswordLink href="/recover">{t('pages:login.forgot_password')}</ForgotPasswordLink>
    <RegisterLink size={1.3}>
      {t('pages:login.new_here')}
          &nbsp;
      <Link inline href="/register">
        <Typo color="primary">{t('pages:login.create_account')}</Typo>
      </Link>
    </RegisterLink>
  </>
);

const DisplayOTPForm = ({ getSesstionTokenWithOTP, t, jwt }) => (
  <Form onSubmit={({ code }) => getSesstionTokenWithOTP({ code, jwt })}>
    {({ submitError, handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        {submitError && <Notification type="error">{submitError}</Notification>}
        <InputWrapper>
          <Field
            name="code"
            component={MewoInput}
            use={Input}
            type="text"
            label={t('common:form.otp')}
            validate={validator.otp}
          />
        </InputWrapper>

        <FormSubmit type="submit" disabled={submitting} fullWidth>
          {t('pages:login.login')}
        </FormSubmit>
      </form>
    )}
  </Form>
);

const Login = ({ getUserSessionToken, getSesstionTokenWithOTP, t, otp }) => (
  <Wrapper>
    <Content>
      <ContentWrapper>
        <Logo />

        {!otp.required && DisplayForm({ getUserSessionToken, t })}
        {otp.required && DisplayOTPForm({ getSesstionTokenWithOTP, t, jwt: otp.jwt })}

      </ContentWrapper>

      <NoAuthLanguageSwitcher />
    </Content>
    <Background />
  </Wrapper>
);

Login.propTypes = {
  getUserSessionToken: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Login;
